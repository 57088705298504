import styled from '@emotion/styled';
import Box from '@ui-v2/core/Box/Box';
import { mqMin } from '@ui-v2/utils/styleUtils';

export const StationSwitcherWrapperBox = styled(Box)`
  top: 50%;
  right: 16px;
  transform: translate(0, -50%) rotate(90deg);

  ${mqMin[768]} {
    top: 50%;
    right: auto;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
