import Box from '@ui-v2/core/Box/Box';
import Text from '@ui-v2/core/Text/Text';

type Props = {
  title: string;
};

const StationPickerLabelItem = ({ title }: Props) => {
  return (
    <Box mb={8} mt={16} px={16}>
      <Text as="div" variant="heading-6">
        {title}
      </Text>
    </Box>
  );
};

export default StationPickerLabelItem;
