import { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { UseComboboxGetItemPropsOptions } from 'downshift';
import { TransportType } from '@codegen/offerAPI';
import useCmsTranslation from '@hooks/useCmsTranslation';
import Box from '@ui-v2/core/Box/Box';
import Icon, { IconProps } from '@ui-v2/core/Icon/Icon';
import Text from '@ui-v2/core/Text/Text';
import { SearchState, StationListNode } from '@web/context/SearchWidgetContext';
import { isStationListLocationNode } from '../hooks/useStationPicker';

const ItemBox = styled(Box)(() => [
  css`
    cursor: pointer;
    transition: background-color 100ms;
  `,
]);

const ItemContentBox = styled(Box)(() => [
  css`
    transition: color 100ms;

    img {
      transition: filter 100ms;
    }
  `,
]);

const transportTypeOutboundIconMapper: Record<
  TransportType,
  IconProps['type']
> = {
  AIRPORT: 'flightOutboundIcon',
  TRAIN: 'trainIcon',
  AIRPORT_AND_TRAIN: 'trainAndPlaneIcon',
};

const transportTypeInboundIconMapper: Record<TransportType, IconProps['type']> =
  {
    AIRPORT: 'flightInboundIcon',
    TRAIN: 'trainIcon',
    AIRPORT_AND_TRAIN: 'trainAndPlaneIcon',
  };

export type StationPickerItemProps =
  UseComboboxGetItemPropsOptions<StationListNode> & {
    city: string;
    isHighlighted: boolean;
    item: StationListNode;
    level: 1 | 2;
    pickerType: SearchState;
  };

const StationPickerItem = forwardRef<HTMLDivElement, StationPickerItemProps>(
  (
    { city, isHighlighted, item, level, pickerType, ...props },
    forwardedRef,
  ) => {
    const { t } = useCmsTranslation();
    const transportTypeIconMapper =
      pickerType === 'selecting-take-off-station'
        ? transportTypeOutboundIconMapper
        : transportTypeInboundIconMapper;

    return (
      <ItemBox
        alignItems="center"
        borderBottom="subdued"
        display="flex"
        {...props}
        as="li"
        bg={isHighlighted ? 'surface.selected.hover' : 'surface.main'}
        gap={16}
        pl={level === 2 ? 24 : 16}
        pr={16}
        py={level === 2 ? 4 : 8}
        ref={forwardedRef}
        width="auto"
      >
        <Icon
          colour="icons.default"
          height={18}
          type={
            item.transportType
              ? transportTypeIconMapper[item.transportType as TransportType]
              : 'globeIcon'
          }
          width={18}
        />
        <ItemContentBox colour="components.date.text.default">
          <Text as="div" variant="body-1">
            {item.value}
          </Text>
          <Text as="div" variant="body-3">
            {isStationListLocationNode(item)
              ? t(
                  'distance_from_city',
                  `${Math.round(item.distance)} km from ${city}`,
                  {
                    distance: Math.round(item.distance),
                    city,
                  },
                )
              : item.country}
          </Text>
        </ItemContentBox>
      </ItemBox>
    );
  },
);

export default StationPickerItem;
