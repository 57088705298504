import { useEffect, useState } from 'react';
import usePartnerPassengersConfig from '@web/context/hooks/usePassengersConfig';
import { useSearchWidget } from '@web/context/SearchWidgetContext';

export const usePassengerCounter = () => {
  const { handleSetPassengers, handleSetSearchState, passengers, searchState } =
    useSearchWidget();

  const { passengerRules } = usePartnerPassengersConfig();

  const numberOfSelectedPassengers = Object.keys(passengers).reduce(
    (prev, curr) => {
      return prev + (passengers[curr]?.length ?? 0);
    },
    0,
  );

  // If we're getting a selected value from the search url or the local storage
  // We set the counter to open by default
  const [openState, setOpenState] = useState<'idle' | 'opened'>(
    numberOfSelectedPassengers > 1 ? 'opened' : 'idle',
  );

  useEffect(() => {
    if (searchState === 'selecting-passengers' && openState === 'idle') {
      setOpenState('opened');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState]);

  const handleInputFocus = () => {
    handleSetSearchState('selecting-passengers');
  };

  const handleChangePassengerAge = ({
    index,
    type,
    value,
  }: {
    index: number;
    type: string;
    value: number;
  }) => {
    handleSetPassengers({
      ...passengers,
      [type]:
        passengers[type]?.map((curr, i) => (i === index ? value : curr)) ?? [],
    });
  };

  const isOpen = searchState === 'selecting-passengers';

  return {
    passengerRules,
    handleInputFocus,
    handleChangePassengerAge,
    searchState,
    openState,
    numberOfSelectedPassengers,
    handleSetSearchState,
    passengers,
    isOpen,
    handleSetPassengers,
  };
};
