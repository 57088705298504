import { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TabButtonVariant } from '@ui-v2/types/buttons';
import { ThemeMarginProps } from '@ui-v2/types/props';
import { buildResponsiveValues } from '@ui-v2/utils/buildResponsiveValues';
import { createTypography } from '@ui-v2/utils/styleUtils';
import { buildMargin } from '@ui-v2/utils/themePropBuilders';
import { buildTabButtonVariant } from './variants';

const TRANSITION_SPEED = '100ms';

export type TabButtonProps = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  'ref'
> &
  ThemeMarginProps & {
    isActive?: boolean;
    variant?: TabButtonVariant;
    width?: 'full' | 'default';
  };

const StyledTabButton = styled('button')<
  TabButtonProps & {
    isActive: NonNullable<TabButtonProps['isActive']>;
    variant: NonNullable<TabButtonProps['variant']>;
  }
>(({ isActive, theme, variant, width, ...props }) => [
  createTypography(theme.typography.heading06),
  // Non responsive values
  css`
    position: relative;
    display: inline-flex;
    overflow: hidden;
    height: max-content;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    padding: ${theme.spacings[4]}px 0;
    border: none;
    border-radius: ${theme.shape.input}px;
    background: transparent;
    cursor: pointer;
    transition:
      color ${TRANSITION_SPEED} ease,
      background-color ${TRANSITION_SPEED} ease,
      box-shadow ${TRANSITION_SPEED} ease,
      border-color ${TRANSITION_SPEED} ease,
      opacity ${TRANSITION_SPEED} ease;

    &:disabled {
      cursor: default;
      opacity: 0;
    }
  `,
  width === 'full' &&
    css`
      width: 100%;
    `,
  // Responsive values
  css(
    buildResponsiveValues({
      ...buildMargin(props),
      ...buildTabButtonVariant(variant, theme, isActive),
    }),
  ),
]);

const TabButton = forwardRef<HTMLButtonElement, TabButtonProps>(
  ({ children, isActive = false, variant = 'primary', ...props }, ref) => {
    return (
      <StyledTabButton
        {...props}
        isActive={isActive}
        ref={ref}
        variant={variant}
      >
        {children}
      </StyledTabButton>
    );
  },
);

TabButton.displayName = 'TabButton';

export default TabButton;
