import { useGetClosestStationsQuery } from '@codegen/gatewayUtils';
import {
  StationListLocationNode,
  StationListNode,
} from '../SearchWidgetContext';

type Props = {
  maxDistanceKM?: number;
  maxNumberOfResults?: number;
  stations: StationListNode[];
};

const sortClosestStations = (
  a: StationListLocationNode,
  b: StationListLocationNode,
) => {
  // If both stations have the same rank, we choose the one who's closer
  if (a.rank === b.rank) {
    return a.distance - b.distance;
  }

  // Be default; pick the one with the higher rank
  return b.rank - a.rank;
};

export const useNearbyStationListNodes = ({
  maxDistanceKM = 500,
  maxNumberOfResults = 10,
  stations,
}: Props) => {
  const { data, isLoading } = useGetClosestStationsQuery({
    maxDistanceKM,
    maxNumberOfResults,
  });

  const closestStations = data?.closestStations?.stations;

  // Build a list of station list nodes by matching the closestStation to StationListNodes
  const matches = stations.reduce<StationListLocationNode[]>((prev, curr) => {
    const match = closestStations?.find(
      (closestStation) => closestStation?.code === curr.code,
    );

    if (!match) {
      return prev;
    }

    return [
      ...prev,
      {
        ...curr,
        distance: match.distance,
        location: match.location,
      },
    ];
  }, []);

  // Create a list of the best matches
  // Currently we sort them by rank and pick the front three
  const nearbyStations = [...matches].sort(sortClosestStations).slice(0, 3);

  return {
    nearbyStations,
    city: data?.closestStations?.city ?? '',
    isLoading,
  };
};
