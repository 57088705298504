import { useConstants } from '@context/ConstantContext';
import useCmsTranslation from '@hooks/useCmsTranslation';
import useIsHandheld from '@hooks/useIsHandheld';
import DateRangePicker from '@ui-v2/components/DateRangePicker/DateRangePicker';
import Box from '@ui-v2/core/Box/Box';
import Button from '@ui-v2/core/Button/Button';
import Input from '@ui-v2/core/Forms/Input/Input';
import Modal from '@ui-v2/core/Modal/Modal';
import { toUTCLocaleString } from '@utils/dateUtils';
import useGetAvailability from '@web/context/hooks/useGetAvailability';
import {
  SearchState,
  Station,
  Trip,
  useSearchWidget,
} from '@web/context/SearchWidgetContext';
import { useSettings } from '@web/context/SettingsContext';
import Notification from '@web/core/Notification/Notification';
import {
  constructStationQueryParameters,
  renderDatePickerInputString,
} from '@web/utils/search/searchWidgetUtils';

export type MobileDatePickerProps = {
  activeTrip: 'round-trip' | 'one-way';
  dateRange: DateRange;
  handleSetDateRange: (range: DateRange) => void;
  handleSetSearchState: (state: SearchState) => void;
  onToggleTripType: (type: Trip) => void;
  searchState: SearchState;
  stations: {
    destination: Station | null;
    origin: Station | null;
  };
};

const MobileDatePicker = () => {
  const {
    activeTrip,
    dateRange,
    destinationStations,
    handleSetDateRange,
    handleSetSearchState,
    originStations,
    searchState,
    stations,
    weekStartsOn,
  } = useSearchWidget();

  const { locale } = useConstants();
  const { residency } = useSettings();
  const { width } = useIsHandheld();

  const { t } = useCmsTranslation();

  const {
    checkIfArrivalDateIsDisabled,
    checkIfDepartureDateIsDisabled,
    isForceOneWay,
  } = useGetAvailability({
    origins: stations.origin
      ? constructStationQueryParameters({
          code: stations.origin.code,
          originalItems: originStations,
        })
      : [],
    destinations: stations.destination
      ? constructStationQueryParameters({
          code: stations.destination.code,
          originalItems: destinationStations,
        })
      : [],
  });

  const handleDateRange = (newRange: DateRange) => {
    handleSetDateRange(newRange);
  };

  return (
    <Box
      display="flex"
      flexDirection={['column', 'column', 'row']}
      gap={[4, 4, 16]}
    >
      <Box flex="1 1 auto">
        <Input
          aria-label={t('departure', 'Departure')}
          iconLeft="calendarIcon"
          id="origin-datepicker-input-mobile"
          label={t('departure', 'Departure')}
          name="date-departure"
          onFocus={handleSetSearchState.bind(null, 'selecting-departure-date')}
          overriddenActiveState={
            searchState === 'selecting-departure-date' ||
            dateRange.from !== null
          }
          overriddenFocusState={searchState === 'selecting-departure-date'}
          placeholder={t('departure', 'Departure')}
          readOnly
          value={renderDatePickerInputString({
            date: dateRange.from,
            residency,
            locale,
          })}
          variant="floating-label"
        />
      </Box>

      {activeTrip === 'round-trip' && (
        <Box flex="1 1 auto">
          <Input
            aria-label={t('return', 'Return')}
            iconLeft="calendarIcon"
            id="destination-datepicker-input-mobile"
            label={t('return', 'Return')}
            name="date-arrival"
            onFocus={handleSetSearchState.bind(null, 'selecting-arrival-date')}
            overriddenActiveState={
              searchState === 'selecting-arrival-date' || dateRange.to !== null
            }
            overriddenFocusState={searchState === 'selecting-arrival-date'}
            placeholder={t('return', 'Return')}
            readOnly
            value={renderDatePickerInputString({
              date: dateRange.to,
              locale,
              residency,
            })}
            variant="floating-label"
          />
        </Box>
      )}
      <Modal
        footer={
          <Box display="flex" flexGrow={0} justifyContent="space-between">
            <Button
              onClick={handleSetSearchState.bind(null, 'idle')}
              size="small"
              type="button"
              variant="secondary"
            >
              {t('Cancel', 'Cancel')}
            </Button>
            {dateRange.from && (
              <Button
                onClick={handleSetSearchState.bind(null, 'idle')}
                size="small"
                type="button"
              >
                {t('Confirm', 'Confirm')}
              </Button>
            )}
          </Box>
        }
        header={
          <Box display="flex" gap={4} width="full">
            <Input
              aria-label={t('departure', 'Departure')}
              iconLeft="calendarIcon"
              label={t('departure', 'Departure')}
              name="date-departure"
              onFocus={handleSetSearchState.bind(
                null,
                'selecting-departure-date',
              )}
              overriddenActiveState={
                searchState === 'selecting-departure-date' ||
                dateRange.from !== null
              }
              overriddenFocusState={searchState === 'selecting-departure-date'}
              placeholder={t('departure', 'Departure')}
              readOnly
              value={
                dateRange.from
                  ? toUTCLocaleString({
                      date: dateRange.from.toDate(),
                      locale,
                      residency,
                      options: {
                        day: 'numeric',
                        month: 'short',
                      },
                    })
                  : ''
              }
              variant="floating-label"
            />
            {activeTrip === 'round-trip' && (
              <Input
                aria-label={t('return', 'Return')}
                iconLeft="calendarIcon"
                label={t('return', 'Return')}
                name="date-arrival"
                onFocus={handleSetSearchState.bind(
                  null,
                  'selecting-arrival-date',
                )}
                overriddenActiveState={
                  searchState === 'selecting-arrival-date' ||
                  dateRange.to !== null
                }
                overriddenFocusState={searchState === 'selecting-arrival-date'}
                placeholder={t('return', 'Return')}
                readOnly
                value={
                  dateRange.to
                    ? toUTCLocaleString({
                        date: dateRange.to.toDate(),
                        locale,
                        residency,
                        options: {
                          day: 'numeric',
                          month: 'short',
                        },
                      })
                    : ''
                }
                variant="floating-label"
              />
            )}
          </Box>
        }
        id="mobile-date-picker-modal"
        isOpen={
          searchState === 'selecting-departure-date' ||
          searchState === 'selecting-arrival-date'
        }
        mobileSize="full-screen"
        onOpenChange={handleSetSearchState.bind(null, 'idle')}
        showCloseButton={false}
      >
        <Box display="flex" flexDirection="column" height="100%">
          <Box
            display="flex"
            flexGrow={2}
            justifyContent="center"
            overflowY="auto"
            p={16}
          >
            <DateRangePicker
              dateRange={{
                from: dateRange.from,
                // When selecting arrival date, force an empty value
                to:
                  searchState === 'selecting-arrival-date'
                    ? undefined
                    : dateRange.to,
              }}
              disabledDays={(date) => {
                if (searchState === 'selecting-departure-date') {
                  return checkIfDepartureDateIsDisabled(date);
                } else {
                  return checkIfArrivalDateIsDisabled(date, dateRange.from);
                }
              }}
              isOneWay={activeTrip === 'one-way'}
              locale={locale}
              numberOfMonths={(width ?? 0) >= 768 ? 2 : 1}
              setDateRange={handleDateRange}
              weekStartsOn={weekStartsOn}
            />
          </Box>
          {isForceOneWay && (
            <Box mt={16}>
              <Notification size="small" variant="warning">
                {t(
                  'no_return_flights_available',
                  'There are no return flights available for this itinerary',
                )}
              </Notification>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default MobileDatePicker;
