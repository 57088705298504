import { forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useCmsTranslation from '@hooks/useCmsTranslation';
import Box from '@ui-v2/core/Box/Box';
import Button from '@ui-v2/core/Button/Button';
import Input from '@ui-v2/core/Forms/Input/Input';
import Modal from '@ui-v2/core/Modal/Modal';
import { IconType } from '@ui-v2/types/typography';
import {
  SearchState,
  StationListNode,
  useSearchWidget,
} from '@web/context/SearchWidgetContext';
import StationPickerResults from '../components/StationPickerResults';
import { useStationPicker } from '../hooks/useStationPicker';

const ResultsWrapperBox = styled(Box)(
  ({ theme }) => css`
    flex-grow: 2;
    margin: -${theme.spacings[16]}px;
    overflow-y: auto;
  `,
);

type MobileStationPickerProps = {
  iconLeft: IconType;
  items: StationListNode[];
  name: string;
  onSelection: (item: StationListNode | null | undefined) => void;
  pickerType: SearchState;
  placeholder: string;
  selection: StationListNode | null;
};

const MobileStationPicker = forwardRef<
  HTMLInputElement,
  MobileStationPickerProps
>(
  (
    {
      iconLeft,
      items: defaultItems,
      name,
      onSelection,
      pickerType,
      placeholder,
      selection,
    },
    forwardedRef,
  ) => {
    const {
      handleSetSearchState,
      isDestinationStationsLoading,
      isOriginStationsLoading,
      searchState,
    } = useSearchWidget();

    const {
      city,
      getInputProps,
      getItemProps,
      getMenuProps,
      highlightedIndex,
      inputValue,
      isShowingCTAMenu,
      isShowingNoResultsMessage,
      isShowingResults,
      items,
      selectedItem,
      setCTAMenu,
      setInputValue,
    } = useStationPicker({
      defaultItems,
      onSelection,
      pickerType,
      searchState,
      selection,
    });

    const { t } = useCmsTranslation();

    const handleInputFocus = () => {
      if (searchState !== pickerType) {
        handleSetSearchState(pickerType);
      }

      // Show the CTA menu on focus
      setCTAMenu();

      // Always clear the input on focus
      if (selectedItem) {
        setInputValue('');
      }
    };

    const handleInputBlur = () => {
      /**
       * If an item has been selected, set that as the value
       * Otherwise clear the input
       */
      if (selectedItem) {
        setInputValue(selectedItem.value);
      } else if (inputValue !== '') {
        setInputValue('');
      }
    };

    const handleCloseModal = () => handleSetSearchState('idle');

    const isOpen = searchState === pickerType;

    return (
      <Box>
        <Input
          iconLeft={iconLeft}
          id={`${placeholder}-input-mobile`}
          isLoading={
            (isDestinationStationsLoading || isOriginStationsLoading) && isOpen
          }
          label={placeholder}
          name={name}
          onFocus={handleSetSearchState.bind(null, pickerType)}
          overriddenActiveState={
            searchState === pickerType || selection !== null
          }
          overriddenFocusState={searchState === pickerType}
          placeholder={placeholder}
          readOnly
          ref={forwardedRef}
          value={inputValue}
          variant="floating-label"
        />
        {/* Display dummy elements when closed to appease downshift */}
        {!isOpen ? (
          <Box display="none">
            <div ref={getMenuProps().ref} />
            <input {...getInputProps()} />
          </Box>
        ) : (
          <Modal
            footer={
              <Box display="flex" justifyContent="space-between">
                <Button
                  onClick={handleSetSearchState.bind(null, 'idle')}
                  size="small"
                  type="button"
                  variant="secondary"
                >
                  {t('Cancel', 'Cancel')}
                </Button>
                {selectedItem && (
                  <Button
                    onClick={handleSetSearchState.bind(null, 'idle')}
                    size="small"
                    type="button"
                  >
                    {t('Confirm', 'Confirm')}
                  </Button>
                )}
              </Box>
            }
            header={
              <Box width="full">
                <Input
                  iconLeft={iconLeft}
                  label={placeholder}
                  name={name}
                  overriddenActiveState
                  overriddenFocusState={false}
                  placeholder={placeholder}
                  variant="floating-label"
                  {...getInputProps({
                    id: `${name}-input`,
                    'aria-controls': `${name}-menu`,
                    'aria-labelledby': `${name}-label`,
                    value:
                      inputValue.substring(0, 15) +
                      (inputValue.length > 15 ? '...' : ''),
                  })}
                  onBlur={handleInputBlur}
                  onFocus={handleInputFocus}
                />
              </Box>
            }
            id="mobile-airport-picker-modal"
            isOpen={isOpen}
            mobileSize="full-screen"
            onOpenChange={handleCloseModal}
            showCloseButton={false}
          >
            <ResultsWrapperBox
              display="flex"
              flexDirection="column"
              height="100%"
              {...getMenuProps({
                id: `${name}-menu`,
                'aria-controls': `${name}-menu`,
                'aria-labelledby': `${name}-label`,
              })}
              flexGrow={0}
            >
              <StationPickerResults
                city={city}
                getItemProps={getItemProps}
                highlightedIndex={highlightedIndex}
                isShowingCTAMenu={isShowingCTAMenu}
                isShowingNoResultsMessage={isShowingNoResultsMessage}
                isShowingResults={isShowingResults}
                items={items}
                pickerType={pickerType}
              />
            </ResultsWrapperBox>
          </Modal>
        )}
      </Box>
    );
  },
);

export default MobileStationPicker;
