import { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DayPicker, Modifiers, Matcher, DayPickerBase } from 'react-day-picker';
import { Language } from '@shared/types/enums';
import { isDateInPast, parseDateString } from '@utils/dateUtils';
import { StyledDayPicker } from './DateRangePicker.styles';
import { setNewDate } from './utils';

export type DateRange = {
  from: Dayjs | null | undefined;
  to: Dayjs | null | undefined;
};

export interface Props {
  dateRange: DateRange;
  disabledDays?: (date: Dayjs) => boolean;
  isOneWay?: boolean;
  locale: Language;
  modifiers?: Partial<Modifiers>;
  numberOfMonths: number;
  setDateRange: (dateRange: DateRange) => void;
  weekStartsOn?: DayPickerBase['weekStartsOn'];
}

const DatePickerCalendar = ({
  dateRange,
  disabledDays,
  isOneWay = false,
  locale,
  modifiers,
  numberOfMonths,
  setDateRange,
  weekStartsOn,
}: Props) => {
  const from = dateRange.from ? dayjs(dateRange.from).toDate() : null;
  const to = dateRange.to ? dayjs(dateRange.to).toDate() : null;

  const [enteredDay, setEnteredDay] = useState(to);
  const selectedDays =
    isOneWay && from ? from : [from, { from, to: to || enteredDay }];

  return (
    <StyledDayPicker>
      <DayPicker
        defaultMonth={from || new Date()}
        formatters={{
          formatCaption: (date: Date) =>
            // eslint-disable-next-line no-restricted-syntax
            date.toLocaleString(locale, {
              month: 'long',
              year: 'numeric',
              hour12: false,
            }),
          formatWeekdayName: (date: Date) =>
            // eslint-disable-next-line no-restricted-syntax
            date.toLocaleString(locale, {
              weekday: 'short',
              hour12: false,
            }),
          formatDay: (date: Date) =>
            // eslint-disable-next-line no-restricted-syntax
            date.toLocaleString(locale, {
              day: 'numeric',
              hour12: false,
            }),
        }}
        initialFocus
        modifiers={{
          ...modifiers,
          range_start: from as Date,
          range_end: isOneWay && from ? from : to || (enteredDay as Date),
          dis: (day: Date) => {
            const parsedDay = parseDateString(
              `${day.getFullYear()}-${day.getMonth() + 1}-${day.getDate()}`,
            );

            return (
              isDateInPast(parsedDay) ||
              (disabledDays ? disabledDays(parsedDay) : false)
            );
          },
        }}
        modifiersClassNames={{ dis: 'rdp-day_disabled' }}
        numberOfMonths={numberOfMonths}
        onDayClick={(day, modifiers) => {
          const parsedDay = parseDateString(
            `${day.getFullYear()}-${day.getMonth() + 1}-${day.getDate()}`,
          );

          if (
            isDateInPast(parsedDay) ||
            (disabledDays ? disabledDays(parsedDay) : false)
          ) {
            return;
          }

          setNewDate(dayjs(day), modifiers, dateRange, setDateRange, isOneWay);
        }}
        onDayMouseEnter={(day: Date) => setEnteredDay(day)}
        selected={selectedDays as Matcher | Matcher[] | undefined}
        weekStartsOn={weekStartsOn}
      />
    </StyledDayPicker>
  );
};

export default DatePickerCalendar;
