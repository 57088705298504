import useCmsTranslation from '@hooks/useCmsTranslation';
import DefaultErrorBoundary from '@ui/components/ErrorBoundary/DefaultErrorBoundary';

const SearchWidgetErrorBoundary = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { t } = useCmsTranslation();

  return (
    <DefaultErrorBoundary
      text={t(
        'Something went wrong in our search widget. If the issue persists please contact us through one of our channels.',
        'Something went wrong in our search widget. If the issue persists please contact us through one of our channels.',
      )}
    >
      {children}
    </DefaultErrorBoundary>
  );
};

export default SearchWidgetErrorBoundary;
